<template>
  <div id="app" :class="'page-'+$route.name" @click="refresh">
    <header v-show="this.$route.name != 'home'" class="header-container">
      <div class="headerTop">
        <div class="navigation" id="nav">
          <router-link to="/"><img alt="Vue logo" src="@/assets/logo.svg"></router-link>
        </div>
        <a class="link-art24" href="#" @click.prevent="showArt24()">
          <svg xmlns="http://www.w3.org/2000/svg" with="30.4" height="40.4" viewBox="0 0 30.4 40.4">
            <path d="M27.9 0H2.4c-.3 0-.6.2-.6.5v24.8c0 .3.3.5.7.5H28c.4 0 .7-.2.7-.5V.5c-.1-.3-.4-.5-.8-.5zm-.7 24.8H3.1V1.1h24.1v23.7z"/><path d="M7.9 6.6h14.5c.3 0 .5-.2.5-.5s-.2-.5-.5-.5H7.9c-.3 0-.5.2-.5.5s.2.5.5.5zM7.9 10.1h14.5c.3 0 .5-.2.5-.5s-.2-.5-.5-.5H7.9c-.3 0-.5.2-.5.5s.2.5.5.5zM7.9 13.6h14.5c.3 0 .5-.2.5-.5s-.2-.5-.5-.5H7.9c-.3 0-.5.2-.5.5s.2.5.5.5zM7.9 17.1h14.5c.3 0 .5-.2.5-.5s-.2-.5-.5-.5H7.9c-.3 0-.5.2-.5.5s.2.5.5.5zM7.9 20.6h14.5c.3 0 .5-.2.5-.5s-.2-.5-.5-.5H7.9c-.3 0-.5.2-.5.5s.2.5.5.5z"/>
            <path d="M1.4 40.3H0l2-6.1h1.7l2 6.1H4.3L3.9 39H1.8l-.4 1.3zm.8-2.6l-.1.2h1.5l-.1-.2c-.2-.6-.3-1.1-.4-1.5-.1-.4-.2-.7-.2-.8v-.2c-.1.4-.3 1.2-.7 2.5zM8 40.3H6.6v-6.1h2.1c.7 0 1.2.2 1.6.5s.6.8.6 1.4c0 .9-.4 1.5-1.1 1.8l1.5 2.3H9.7l-1.3-2.1H8v2.2zm.7-5H8V37h.7c.6 0 .9-.3.9-.9 0-.3-.1-.5-.2-.6-.3-.1-.5-.2-.7-.2zM16.2 35.3h-1.5v4.9h-1.4v-4.9h-1.5v-1.1h4.4v1.1zM18.2 39c.2.2.2.3.2.6s-.1.4-.2.6c-.2.2-.3.2-.6.2-.2 0-.4-.1-.6-.2s-.2-.3-.2-.6.1-.4.2-.6.3-.2.6-.2c.2 0 .4 0 .6.2zM25.2 40.3h-3.9v-1c.6-.4 1.2-.9 1.7-1.5.5-.5.7-1 .7-1.4 0-.3-.1-.5-.2-.6-.2-.1-.4-.2-.7-.2-.5 0-1 .2-1.4.6v-1.3c.2-.1.5-.3.8-.4.3-.1.6-.2.9-.2.6 0 1.1.2 1.5.5.4.3.5.8.5 1.3 0 1-.7 2-2 3h2.1v1.2zM29.7 40.3h-1.3V39h-2.6v-1.1l1.8-3.5h2.1v3.5h.7V39h-.7v1.3zm-2.6-2.4h1.3v-2.7l-1.3 2.7z"/>
          </svg>
        </a>
      </div>
      <h1>
        <img alt="Vue logo" src="@/assets/title.svg">
      </h1>
      <div class="bottom">
        60<br />
        <span>ans</span>
      </div>
    </header>
    <transition name="animArt24">
      <div v-if="activeArt24" class="article24Box-container">
        <div @click.prevent="activeArt24=false" class="overlay"></div>
          <div class="article24Box">
            <a class="link-close" href="#" @click.prevent="activeArt24=false">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.2 22.2">
                <path d="M21.2 22.2c-.3 0-.5-.1-.7-.3L.3 1.7C-.1 1.3-.1.7.3.3s1-.4 1.4 0l20.2 20.2c.4.4.4 1 0 1.4-.2.2-.4.3-.7.3z"/>
                <path d="M1 22.2c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4L20.5.3c.4-.4 1-.4 1.4 0s.4 1 0 1.4L1.7 21.9c-.2.2-.4.3-.7.3z"/>
              </svg>
              Fermer
            </a>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.4 46.8"><path fill="#E74967" d="M47.2 0h-46C.5 0 0 .4 0 1v44.8c0 .5.5 1 1.2 1h45.9c.7 0 1.2-.4 1.2-1V1c.1-.6-.5-1-1.1-1zm-1.3 44.8H2.4V1.9h43.5v42.9z"/><path fill="#E74967" d="M11.1 12h26.2c.5 0 .9-.4.9-1 0-.5-.4-1-.9-1H11.1c-.5 0-.9.4-.9 1 0 .5.4 1 .9 1zM11.1 18.3h26.2c.5 0 .9-.4.9-1 0-.5-.4-1-.9-1H11.1c-.5 0-.9.4-.9 1 0 .5.4 1 .9 1zM11.1 24.6h26.2c.5 0 .9-.4.9-1s-.4-1-.9-1H11.1c-.5 0-.9.4-.9 1s.4 1 .9 1zM11.1 30.9h26.2c.5 0 .9-.4.9-1 0-.5-.4-1-.9-1H11.1c-.5 0-.9.4-.9 1s.4 1 .9 1zM11.1 37.2h26.2c.5 0 .9-.4.9-1s-.4-1-.9-1H11.1c-.5 0-.9.4-.9 1s.4 1 .9 1z"/></svg>
            <div class="description">
              <h3>
                Le fondement du Sénat :<br />
                l'article 24 de la Constitution
              </h3>
              <p>
                  « Le Parlement vote la loi. Il contrôle l’action du
                  Gouvernement. Il évalue les politiques publiques.
                  Il comprend l’Assemblée nationale et le Sénat.
                  Les députés à l’Assemblée nationale, dont le
                  nombre ne peut excéder cinq cent
                  soixante-dix-sept, sont élus au suffrage direct. Le
                  Sénat, dont le nombre de membres ne peut
                  excéder trois cent quarante-huit, est élu au
                  suffrage indirect. Il assure la représentation des
                  collectivités territoriales de la République.
                  Les Français établis hors de France sont
                  représentés à l’Assemblée nationale et au Sénat. »
              </p>
            </div>
        </div>
      </div>
    </transition>
    <router-view @resetRefresh="refresh" />
  </div>
</template>

<script>

export default {
  name: 'app',
  data() {
    return {
      refreshTime : '',
      activeArt24 : false
    }
  },
  methods: {
    refresh() {
      var el = this;
      clearTimeout(this.refreshTime);
      this.refreshTime = setTimeout(
        function(){
          //console.log(el+'comebackToHome')
          el.$router.replace({ name: "home" });
        }, (10*1000*60)
      );
    },
    showArt24() {
      this.activeArt24 = true
    }
  },
  mounted () {
    this.refresh();
  }
}
</script>

<style lang="scss">

* {
  box-sizing: border-box;
}

html {
  font-size: 1em;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-family: $fontHind;
}

body {
  height: 100%;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  font-weight: 700;
}

a {
  text-decoration: none;
  display: inline-block;
  transition: all .3s;
  color: $grey33;

  &:hover {
    color: $grey88;
  }
}

button {
  outline: none;
}

.btn {
  position: relative;
  display: inline-block;
  padding: .75em 1.6em;
  background: transparent;
  color: $grey33;
  border-radius: 30px;
  border: solid 2px $grey33;
  transition: all .3s;
  font-weight: 700;

  &:hover {
    border-color: $rose;
    color: $rose;
  }

  &.btn-primary {
    border-color: $rose;
    background: $rose;
    color: #fff;
    &:hover {
      border-color: #fff;
      background-color: #fff;
      color: $rose;
    }
  }
}



p {
  font-family: $fontNoto;
  line-height: 1.5;
}

svg {
  transition: all .3s;
}

.container {
  margin: 0 auto;
}

#app {
  position: relative;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $grey33;
}
#nav {
  a {
    font-weight: bold;
    color: $grey33;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.article24Box-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  .overlay {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: $grey33;
    opacity: .5;
    z-index: 10;
    transition: .5s all;
  }
}

.article24Box {
  position: absolute;
  padding: 11em 3em 3em;
  top: 0;
  left: 0;
  height: 100%;
  width: 28em;
  z-index: 300;
  background: #fff;
  transition: all .5s ease-out;

  .link-close {
    display: flex;
    position: absolute;
    padding: 2em 6em 2em 2em;
    width: 100%;
    left: 0;
    top: 0;
    &:hover {
      color: $red;
      svg {
        fill: $red;
      }
    }

    svg {
      display: inline-block;
      width: 1.5em;
      height: 1.5em;
      margin: 0 1em 0 0;
      fill: $grey33;
    }
  }

  svg {
    display: block;
    max-width: 3em;
    margin: 0 auto 2em;
  }

  h2 {
    font-size: 2.5em;
    line-height: 1;
    margin-bottom: 1em;
    color: $darkblue;
    text-align: center;
  }

  .description {
    color: $grey66;
    h3 {
      font-size: 1.5em;
      font-weight: 400;
      font-family: $fontNoto;
      margin-bottom: 1em;
    }
  }
}

.animArt24-leave {
  .overlay {
    opacity: .5;
  }
  .article24Box {
    transform: translateX(0);
  }
}
.animArt24-leave-active {
  transition: all .5s ease-out;
}
.animArt24-leave-to {
  .article24Box {
    transform: translateX(-100%);
  }
  .overlay {
    opacity: 0;
  }
}

.animArt24-enter {
  .overlay {
    opacity: 0;
  }
  .article24Box {
    transform: translateX(-100%);
  }
}
.animArt24-enter-active {

}
.animArt24-enter-to
/* .animArt24-leave-active below version 2.1.8 */ {
  .overlay {
    opacity: .5;
  }
  .article24Box {
    transform: translateX(0);
  }
}

// red color for text
.red-text {
  color: $rose;
}

</style>
