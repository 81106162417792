<template>
  <div class="line-timeline row">
    <slick
          v-if="cards"
          ref="slick"
          :options="slickOptions">
          <div class="card" v-for="(card, index) in cards" v-bind:key="index" :class="card.type">

            <template  v-if="card.type=='card'" >
              <h3 v-html="card.header"></h3>
              <p class="desc">
                {{card.description}}
              </p>
              <a class="btn" v-if="card.content" href="#" @click.prevent="$emit('showModal', card)">En savoir plus</a>
            </template>

            <template v-else-if="card.type=='president'" >
              <h3 v-html="card.header"></h3>
              <div class="grid-middle">
                <div class="col-4">
                  <div class="imgBox">
                    <img :src="getImgMap(card.image)" />
                  </div>
                </div>
                <p class="desc col" v-html="card.description"></p>
              </div>
              <a class="btn" v-if="card.content" href="#" @click.prevent="$emit('showModal', card)">En savoir plus</a>
            </template>

            <template v-else >
              <h2 class="title-const" v-html="card.title"></h2>
              <p class="subtitle">
                {{card.subtitle}}
              </p>
            </template>

          </div>
        </slick>

  </div>
</template>

<script>

  import cards from '../../public/data/cards.json'
  import Slick from 'vue-slick';
  import '../../node_modules/slick-carousel/slick/slick.css'

  export default {
    name: 'timeline',
    components: { Slick },
    data() {
      return {
        cards: cards,
        slickOptions: {
          //options can be used from the plugin documentation
          slidesToShow: 4,
          slidesToScroll: 3, // on ne peut plus swipe à plus loin que ce qu'on indique.
          infinite: false,
          swipeToSlide: false, // incompatible avec slidesToScroll
          swipe: false,
          responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3,
              }
            }
          ]
        }
      }
    },
    methods: {
      next() {
        this.$refs.slick.next()
      },
      prev() {
        this.$refs.slick.prev()
      },
      reInit() {
        // Helpful if you have to deal with v-for to update dynamic lists
        this.$refs.slick.reSlick()
      },
      getImgMap(img) {
        var url = require('@/assets/presidents/' + img)
        return url;
      },
      showModal: function (data) {
        this.$emit('showModal', data);
      }
    }
  };
</script>

<style lang="scss">
// timeline

.line-timeline {
  position: relative;
  background-color: #ccc;
  overflow: hidden;

  &.row {
    padding: 0 6em 0 1px;
  }

  .slick-list {
    overflow: visible;
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    height: auto;
    margin-left: -1px;
    opacity: .3;

    &:nth-last-child(4),
    &:nth-last-child(3),
    &:nth-last-child(2),
    &.slick-active {
      opacity: 1;
    }
    &.slick-current {

    }

    > div {
      height: 100%;
    }
  }

  .slick-prev {
    font-size: 1em;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 6em;
    border: none;
    background: url("../assets/icon-next.svg") no-repeat calc(100% - 1em) center;
    background-size: 3.75em 3.75em;
    text-indent: -9999px;
    overflow: hidden;
    transform: rotate(180deg);
    outline: none;
    z-index: 200;
    cursor: pointer;
    transition: all .3s;

    &.slick-disabled {
      opacity: 0;
    }
  }

  .slick-next {
    font-size: 1em;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 6em;
    border: none;
    background: url("../assets/icon-next.svg") no-repeat calc(100% - 1em) center;
    background-size: 3.75em 3.75em;
    text-indent: -9999px;
    overflow: hidden;
    transform: translateX(100%);
    outline: none;
    cursor: pointer;
    transition: all .3s;
    z-index: 200;

    &.slick-disabled {
      opacity: 0;
    }
  }
}

.card.intro {
  padding: 3em 5.5em 3em 4em;
}

.slick-list {
  .slick-slide:last-child {
    .card {
      &:before,
      &::after {
        display: none;
      }
    }
  }
}

.card {
  position: relative;
  height: 100%;
  padding: 3em 3em 8em 4.5em;
  background-color: #eff5f7;
  border-top: 1px solid $lightblue;
  border-bottom: 1px solid $lightblue;

  &:after,
  &:before {
    left: calc(100% - 1px);
    top: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    z-index: 10;
  }

  &:after {
    border-color: rgba(136, 183, 213, 0);
    border-left-color: #eff5f7;
    border-width: 24px;
    margin-top: -24px;
    transform: scaleY(9.2);
  }
  &:before {
    border-color: rgba(194, 225, 245, 0);
    border-left-color: #d9dced;
    border-width: 26px;
    margin-top: -26px;
    transform: scaleY(9.2);
  }

  &.president {
    background-color: #f2e9e9;
    &:after {
      border-left-color: #f2e9e9;
    }

    h3 {
      position: relative;
      line-height: 1;
      margin-bottom: .75em;
      font-weight: 300;

      &:after {
        height: 36px;
        width: 36px;
        top: .1em;
        left: -1.1em;
        background: url("../assets/bulle-pdt.svg") no-repeat center center;
      }

      &:before {
        width: 1.8em;
        top: .49em;
        left: -1.2em;
        transform: translateX(-100%);
        background: $rose;
      }
    }
  }

  h3 {
    position: relative;
    font-size: 2.4em;
    line-height: 1;
    margin-bottom: .75em;
    font-weight: 300;

    &:after {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      top: .34em;
      left: -.65em;
      background: url("../assets/bulle-date.svg") no-repeat center center;
    }

    &:before {
      position: absolute;
      content: "";
      height: 2px;
      width: 1.8em;
      top: .49em;
      left: -.7em;
      transform: translateX(-100%);
      background: #7CA6B1;
      z-index: 400;
      opacity: .4;
    }
  }

  .imgBox {
    height: 5.3em;
    width: 5.3em;
    border-radius: 100%;
    overflow: hidden;
    border: solid 2px $lightblue;
  }

  img {
    display: block;
    width: 100%;
  }

  .btn {
    position: absolute;
    left: 4.5em;
    bottom: 2.5em;

  }
}

</style>
