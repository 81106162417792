<template>

  <div class="modal-mask" @click="close" >
    <div class="modal-container modalBook-container" @click.stop>
        <div class="modal-header">
          <a role="button" class="modal-close" type="button" name="button" @click.prevent="close" >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 51"><g fill="#FFF"><path d="M32.5 33.5c-.3 0-.5-.1-.7-.3l-14-14c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l14 14c.4.4.4 1 0 1.4-.2.2-.4.3-.7.3z"/><path d="M18.5 33.5c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l14-14c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-14 14c-.2.2-.4.3-.7.3z"/></g><path fill="#FFF" d="M25.5 51C11.4 51 0 39.6 0 25.5S11.4 0 25.5 0 51 11.4 51 25.5 39.6 51 25.5 51zm0-50C12 1 1 12 1 25.5S12 50 25.5 50 50 39 50 25.5 39 1 25.5 1z"/></svg>
            Fermer
          </a>
          <h3>Les archives de l’avant-projet de Constitution</h3>
        </div>
        <div class="modal-body modal-body-book">
          <div class="redactor-container row">
            <div class="countBox">
              {{ currentSlide }}/{{ slidesCount }}
            </div>
            <slick v-if="books" ref="slick" :options="slickOptions" @init="handleInit" @afterChange="handleAfterChange" >
              <div class="item-book" v-for="(book, index) in books" v-bind:key="index" >
                <template>
                  <div class="grid">
                    <div class="col-5 imgBox">
                      <img :src="getImgMap(book.image)" alt="">
                    </div>
                    <div class="col-7">
                      <div class="legendBox" v-html="book.content">
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </slick>

          </div>
        </div>
    </div>
  </div>

</template>

<script>
  import books from '../../public/data/book.json'
  import Slick from 'vue-slick';
  import '../../node_modules/slick-carousel/slick/slick.css'

  export default {
    name: 'redactor',
    components: { Slick },
    data() {
      return {
        books: books,
        slidesCount: Number,
        currentSlide: 1,
        slickOptions: {
          //options can be used from the plugin documentation
          swipeToSlide: true, // incompatible avec slidesToScroll
        }
      }
    },
    methods: {
      handleInit(event, slick) {
        this.slidesCount = slick.slideCount
      },
      handleAfterChange(event, slick, currentSlide) {
        this.currentSlide = currentSlide+1;
        this.$emit('resetRefresh');
      },
      getImgMap(img) {
        var url = require('@/assets/books/' + img)
        return url;
      },
      close: function () {
        this.$emit('close');
        this.$emit('resetRefresh')
      }
    }
  };
</script>

<style lang="scss">

.modalBook-container {

  .slick-prev,
  .slick-next {
    position: absolute;
    right: 0;
    top: 50%;
    background: url("../assets/bg-fle.svg") no-repeat center center;
    background-size: 3.65em 7em;
    height: 11em;
    width: 5.65em;
    padding: 2em;
    border: none;
    transform: translateX(450%) translateY(-50%);
    overflow: hidden;
    text-indent: -999px;
    cursor: pointer;
  }

  .slick-prev {
    right: auto;
    left: 0;
    transform: translateX(-450%) translateY(-50%) rotate(180deg);
  }
}

.modal-body.modal-body-book {
  position: relative;
  margin: 0 auto;
  padding: 3.5em 6em 4em 4em;

  .imgBox {
    img {
      width: 100%;
    }
  }

  .legendBox {
    padding: 1em;
  }

  .slick-dots {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    padding: 2em 0 0;
    margin: 0 auto;
    width: 16em;
    transform: translateY(-11.8em);

    li {
      display: block;
      position: relative;
      overflow: hidden;
      height: 1em;
      width: 1em;
      border-radius: 1em;
      background-color: #fff;
      text-indent: -9999px;
      cursor: pointer;

      &.slick-active {
        background-color: $rose;
      }
    }
  }

}

</style>
