<template>

  <div class="modal-mask" @click.prevent="close" >
    <div class="modal-container modalRedactor-container" @click.stop>
        <div class="modal-header">
          <a role="button" class="modal-close" type="button" name="button" @click.prevent="close" >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 51"><g fill="#FFF"><path d="M32.5 33.5c-.3 0-.5-.1-.7-.3l-14-14c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l14 14c.4.4.4 1 0 1.4-.2.2-.4.3-.7.3z"/><path d="M18.5 33.5c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l14-14c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-14 14c-.2.2-.4.3-.7.3z"/></g><path fill="#FFF" d="M25.5 51C11.4 51 0 39.6 0 25.5S11.4 0 25.5 0 51 11.4 51 25.5 39.6 51 25.5 51zm0-50C12 1 1 12 1 25.5S12 50 25.5 50 50 39 50 25.5 39 1 25.5 1z"/></svg>
            Fermer
          </a>
          <h3>Pourquoi une nouvelle Constitution en 1958 ?</h3>
          <!-- <div class="grid-middle-noGutter">
            <div class="col-5">
              <h3>Pourquoi une nouvelle Constitution en 1958 ?</h3>
            </div>
            <div class="col-7 dotsBox">
            </div>
          </div> -->
        </div>
        <div class="modal-body modal-body-redactor">
          <div class="redactor-container row">
            <div class="countBox">
              {{ currentSlide }}/6
            </div>
            <slick v-if="redactors" ref="slick" :options="slickOptions" @afterChange="handleAfterChange">
              <div class="item-redactor" v-for="(redactor, index) in redactors" v-bind:key="index" >
                <template>
                  <div class="grid-center">
                    <!-- <div class="col-3">
                      <div class="imgBox">
                        <img :src="getImgMap(redactor.image)" alt="">
                      </div>
                    </div> -->
                    <div class="col-8" >
                      <div v-html="redactor.content"></div>
                    </div>
                  </div>
                </template>
              </div>
            </slick>

          </div>
        </div>
    </div>
  </div>

</template>

<script>
  import redactors from '../../public/data/redactors.json'
  import Slick from 'vue-slick';
  import '../../node_modules/slick-carousel/slick/slick.css'

  export default {
    name: 'redactor',
    components: { Slick },
    data() {
      return {
        redactors: redactors,
        currentSlide: 1,
        slickOptions: {
          swipeToSlide: true, // incompatible avec slidesToScroll
          // dots: true,
          // customPaging: function(slick,index) {
          //   var src = redactors[index]['image'];
          //   var url = require('@/assets/redactors/' + src)
          //   return '<img src="'+ url +'"  />';
          // }
        }
      }
    },
    methods: {
      handleAfterChange(event, slick, currentSlide) {
        this.currentSlide = currentSlide+1;
        this.$emit('resetRefresh');
      },
      getImgMap(img) {
        var url = require('@/assets/redactors/' + img)
        return url;
      },
      close: function () {
        this.$emit('close');
        this.$emit('resetRefresh');
      }
    }
  };
</script>

<style lang="scss">

.item-redactor {

  .imgBox {
    position: relative;
    text-align: center;
    padding: 3px;
    border: solid 1px $grey33;
    border-radius: 100%;
    margin-top: 1em;
    height: 16em;
    width: 16em;
  }

  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    border-radius: 100%;
  }

  h3 {
    font-size: 1.875em;
    line-height: 1;
  }

  p, ul {
    font-size: 1.25em;
    font-family: $fontNoto;
    line-height: 1.5;
  }
}

.modalRedactor-container {
  .modal-close {
    transform: translateX(100px);
  }
  // .modal-header {
  //   h3 {
  //     text-align: left;
  //   }
  // }

  .slick-prev,
  .slick-next {
    position: absolute;
    right: 0;
    top: 16em;
    background: url("../assets/bg-fle.svg") no-repeat center center;
    background-size: 3.65em 7em;
    height: 11em;
    width: 5.65em;
    padding: 2em;
    border: none;
    transform: translateX(450%);
    overflow: hidden;
    text-indent: -999px;
    cursor: pointer;
  }

  .slick-prev {
    right: auto;
    left: 0;
    transform: translateX(-450%) rotate(180deg);
  }
}

.modal-body-redactor {
  position: relative;
  margin: 0 auto;
  max-width: 100%;
  padding: 3.5em 6em 4em 4em;

  h3.name {
    font-family: $fontNoto;
    font-size: 3.75em;
    color: $grey66;
  }

  .slick-dots {
    position: absolute;
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    top: 0;
    right: 0;
    width: 33.75em;
    height: 5em;
    transform: translateY(-12em);

    li {
      cursor: pointer;
      width: 5em;
      position: relative;
      list-style: none;
      border-radius: 100%;
      overflow: hidden;
      border: 3px solid transparent;
      transition: all .3s ease-out;
      opacity: .5;

      &.slick-active {
        opacity: 1;
        border: 3px solid $blueTurquoise;
      }

      &:hover {
        opacity: 1;
      }
    }

    img {
      display: block;
      max-width: 100%;
    }
  }
}

.countBox {
  position: absolute;
  right: .75em;
  top: .5em;
  font-family: $fontNoto;
  font-size: 2em;
  color: $rose;
}

</style>
