<template>
  <div class="constitution">
    <div class="first-line">
      <div class="list-first-line grid-noGutter">
        <div class="col-3 firstList--item">
          <div class="descBox">
            <h2 class="title-const" v-html="data.constTitle"></h2>
            <p v-html="data.constDesc"></p>
          </div>
        </div>
        <div class="col-9 grid-noGutter">
          <div class="col-6 firstList--item" v-for="(video, index) in data.videos" v-bind:key="index">
            <a class="link-video" :class="'link-video-'+(index+1)" href="#" @click.prevent="showVideo(video)">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 84.7 84.7"><g fill="#FFF"><path d="M59 42.3L34.2 26.9v30.8z"/><path d="M42.3 84.7C19 84.7 0 65.7 0 42.3 0 19 19 0 42.3 0c23.3 0 42.3 19 42.3 42.3.1 23.4-18.9 42.4-42.3 42.4zm0-81.7C20.6 3 3 20.6 3 42.3 3 64 20.6 81.7 42.3 81.7S81.7 64 81.7 42.3 64 3 42.3 3z"/></g></svg>
              <div class="description">
                <h3>{{ video.title }}</h3>
                <p>{{ video.desc }}</p>
              </div>
            </a>
          </div>
        </div>

      </div>
    </div>



    <div class="footer-line">
      <div class="list-first-line grid-noGutter">
        <div class="col footerList--item">
          <div class="descBox">
            <h2 class="title-const" v-html="data.redactorTitle"></h2>
            <p>{{ data.redactorDesc }}</p>
          </div>
        </div>

        <div class="col-9 grid-noGutter">
          <div class="col-6 footerList--item first">
            <div class="description">
              <h3>{{ data.redactorFirstBoxTitle }}</h3>
              <p>
                {{ data.redactorFirstBoxDesc }}
              </p>
              <a class="btn" href="#" @click.prevent="showModalRedac">Toutes les étapes</a>
            </div>
          </div>

          <div class="col-6 footerList--item second">
            <div class="description">
              <h3>{{ data.redactorSecondBoxTitle }}</h3>
              <p>
                {{ data.redactorSecondBoxDesc }}
              </p>
              <a class="btn" href="#" @click.prevent="showModalBook">En savoir plus</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <timeline @showModal="showModalCard" />

    <transition name="modal">
      <VideoModal v-if="showVideoModal" @close="showVideoModal = false" @resetRefresh="$emit('resetRefresh')">
        <h3 slot="header">{{ modalVideo.popTitle}}</h3>
        <template slot="main">
          <iframe frameborder="0" width="480" height="270" :src="modalVideo.iframeSrc" allowfullscreen="" allow="autoplay"></iframe>
          <div class="description">
            <div class="title">
              {{ modalVideo.title}}
            </div>
            <p>
              {{ modalVideo.desc}}
            </p>
          </div>
        </template>
      </VideoModal>
    </transition>

    <transition name="modal">
      <RedactorModal v-if="showredactModal" @close="showredactModal = false" @resetRefresh="$emit('resetRefresh')" />
    </transition>

    <transition name="modal">
      <BookModal v-if="showBookModal" @close="showBookModal = false" @resetRefresh="$emit('resetRefresh')" />
    </transition>

    <transition name="modal">
      <div v-if="showCardModal" class="modal-mask" @click="showCardModal = false" >
        <div class="modal-container modal-card-container" @click.stop>
            <div class="modal-header">
              <a role="button" class="modal-close" type="button" name="button" @click.prevent="showCardModal = false">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 51"><g fill="#FFF"><path d="M32.5 33.5c-.3 0-.5-.1-.7-.3l-14-14c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l14 14c.4.4.4 1 0 1.4-.2.2-.4.3-.7.3z"/><path d="M18.5 33.5c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l14-14c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-14 14c-.2.2-.4.3-.7.3z"/></g><path fill="#FFF" d="M25.5 51C11.4 51 0 39.6 0 25.5S11.4 0 25.5 0 51 11.4 51 25.5 39.6 51 25.5 51zm0-50C12 1 1 12 1 25.5S12 50 25.5 50 50 39 50 25.5 39 1 25.5 1z"/></svg>
                Fermer
              </a>
              <h3 v-html="card.header"></h3>
            </div>
            <div class="modal-body modal-body-card" v-html="card.content">

            </div>
        </div>
      </div>
    </transition>

  </div>
</template>

<script>
// @ is an alias to /src
const $ = require('jquery')
window.$ = $

import Data from '../../public/data/constitution.json'
import Timeline from '@/components/timeline.vue'
import RedactorModal from '@/components/RedactorModal.vue'
import BookModal from '@/components/BookModal.vue'
import VideoModal from '@/components/videoModal.vue'

export default {
  name: 'home',
  components: {
    Timeline,
    VideoModal,
    RedactorModal,
    BookModal
  },
  data() {
    return {
      card: '',
      modalVideo: '',
      showVideoModal: false,
      showBookModal: false,
      showredactModal: false,
      showCardModal: false,
      data: Data
    }
  },
  methods: {
    backToTop() {
      $('html,body').stop().animate({
        scrollTop: 0
      }, 0);
    },
    showVideo(video) {
      this.modalVideo = video;
      this.showVideoModal = true;
      this.backToTop();
    },
    showModalCard(card) {
      this.card = card;
      this.showCardModal = true;
      this.backToTop();
    },
    showModalBook() {
      this.showBookModal=true;
      this.backToTop();
    },
    showModalRedac() {
      this.showredactModal=true;
      this.backToTop();
    }
  }
}
</script>

<style lang="scss">
.constitution {
  text-align: left;
  position: relative;
  padding: 0 0 0 4.4em;

  .title-const {
    font-size: 1.875em;
    line-height: 1.1;

    svg {
      height: .8em;
      width: .8em;
      margin: 0 .2em -.1em 0;
    }
  }
}

.header-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  background-color: #fff;
  width: 4.375em;
  z-index: 100;
  border-right: 1px solid $lightblue;

  .headerTop {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + 1px);
  }

  .navigation {
    margin-bottom: 1em;

    a {
      display: block;
      padding: .75em;
    }
    img {
      width: 2.5em;
      display: block;
      margin: 0 auto ;
    }
  }

  .link-art24 {
    text-align: center;
    padding: .93em 1em;
    display: block;
    background-color: $rose;
    color: #fff;
    width: 100%;
    height: 4.5em;

    &:hover {
      background: $lightblue;

      svg {
        fill: $grey33;
      }
    }

    svg {
      display: block;
      max-width: 2em;
      margin: 0 auto;
      fill: #fff;
    }
  }

  h1 {
    img {
      width: 32px;
    }
  }

  .bottom {
    text-align: center;
    position: absolute;
    padding: .25em 0;
    width: 100%;
    bottom: 0;
    left: 0;
    color: $rose;
    font-family: $fontNoto;
    font-size: 2em;
    line-height: .85;

    span {
      display: block;
      font-size: .75em;
      color: $darkblue;
    }
  }
}

.row {
  padding: 0 1em;
}

// first-line

.firstList--item {

  .descBox {
    position: relative;
    display: block;
    padding: 2.75em 5.5em 2.75em 4em;
  }

  .link-video {
    position: relative;
    display: flex;
    align-items: flex-end;
    padding: 2em 9em 2em 4em;
    height: 100%;
    width: 100%;
    color: #fff;
    background: url(../assets/bg-video.jpg) no-repeat center center;
    background-size: cover;

    &:hover {
      &:before {
        opacity: .6;
      }
    }

    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background-color: #585979;
      z-index: 5;
      opacity: .8;
      transition: all .4s ease-out;
    }

    &.link-video-2 {
      background-image: url(../assets/bg-video-2.jpg);
      &:before {
        background-color: $green;
      }
    }

    svg {
      display: block;
      position: absolute;
      right: 2em;
      top: 2em;
      height: 5em;
      width: 5em;
      z-index: 30;
    }
  }

  .description {
    position: relative;
    z-index: 30;
    width: 100%;
    min-height: 7em;

    h3 {
      font-size: 2em;
      line-height: 1;
      margin-bottom: .25em;
    }
    p {
      margin: 0;
    }
  }
}

// footer-line

.footerList--item {
  .descBox {
    border-top: 1px solid $lightblue;
    padding: 2.75em 5.5em 2.75em 4em;
  }

  .description {
    display: block;
    height: 100%;
    padding: 2em;
    padding-right: 20em;
    background: url("../assets/bg-redactor.jpg") no-repeat top right $orange;
    background-size: auto 21.3em;

    h3 {
      font-size: 2em;
      margin-bottom: 0;
      line-height: 1.2;
    }

    p {
      margin-bottom: 2em;
    }

  }

  &.second {
    .description {
      background-image: url("../assets/bg-book.jpg");
      background-color: $lightblue;
    }
  }
}

// modal-body-card

.modal-body-card {
  margin: 0 auto;
  max-width: 50em;
  padding: 2em 4em;

  h3 {
    font-size: 2.25em;
    font-family: $fontNoto;
    font-weight: 400;
    margin-bottom: .75em;
  }

  ul {
    padding-left: 2em;
  }

  li {
    font-family: $fontNoto;
  }
}


</style>
