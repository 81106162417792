<template>
  <div class="modal-mask" @click="close" >
    <div class="modal-container modal-video-container" @click.stop>
        <div class="modal-header">
          <a role="button" class="modal-close" type="button" name="button" @click.prevent="close">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 51"><g fill="#FFF"><path d="M32.5 33.5c-.3 0-.5-.1-.7-.3l-14-14c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l14 14c.4.4.4 1 0 1.4-.2.2-.4.3-.7.3z"/><path d="M18.5 33.5c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l14-14c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-14 14c-.2.2-.4.3-.7.3z"/></g><path fill="#FFF" d="M25.5 51C11.4 51 0 39.6 0 25.5S11.4 0 25.5 0 51 11.4 51 25.5 39.6 51 25.5 51zm0-50C12 1 1 12 1 25.5S12 50 25.5 50 50 39 50 25.5 39 1 25.5 1z"/></svg>
            Fermer
          </a>
          <slot name="header"></slot>
        </div>
        <div class="modal-body video">
          <slot name="main"></slot>
        </div>
        <div class="modal-footer">
          <slot name="footer"></slot>
        </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'videoModal',
    data() {
      return {

      }
    },
    methods: {
      close: function () {
        this.$emit('close');
        this.$emit('resetRefresh');
      }
    }
  };
</script>

<style lang="scss" >

.modal-mask {
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity .3s ease;

  &:before {
    position: fixed;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .95);
    transition: opacity .3s ease;
  }
}

.modal-container {
  position: relative;
  max-width: 90em;
  margin: 4em auto 0;
  padding: 2rem 3rem;
  transition: all .3s ease;

  &.modal-card-container {
    max-width: 60em;
  }

  &.modal-video-container {
    max-width: 72em;
  }
}

.modal-header {
  padding: 1rem 0;
  margin-bottom: 2rem;
  text-align: center;
  h3 {
    font-size: 4em;
    line-height: 1;
    margin: 0;
    color: #fff;
  }
}

.modal-body {
  margin: 2rem 0;
  border-radius: 5px;

  &:not(.video) {
    background: #fff;
  }

  iframe {
    display: block;
    margin: 0 auto 1em;
    width: 1000px;
    height: 562px;
  }

  .description {
    padding: 1em 0;
    color: #fff;
    text-align: center;
    max-width: 900px;
    margin: 0 auto;

    .title {
      font-size: 2.5em;
      font-weight: 700;

    }
  }
}

.modal-close {
  display: flex;
  align-items: center;
  position: absolute;
  padding: 1.5em 1.5em;

  top: -3em;
  right: -4em;
  color: #fff;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
  font-size: 1em;

  svg {
    display: block;
    flex-shrink: 0;
    width: 3em;
    height: 3em;
    margin: 0 1em 0 0;
    fill: #fff;
  }
}

.text-right {
  text-align: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}


</style>
