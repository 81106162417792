import { render, staticRenderFns } from "./Constitution.vue?vue&type=template&id=49f80aec&"
import script from "./Constitution.vue?vue&type=script&lang=js&"
export * from "./Constitution.vue?vue&type=script&lang=js&"
import style0 from "./Constitution.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "Constitution.vue"
export default component.exports