<template>
  <div class="home">
    <transition name="logoAppear">
      <img v-show="animAppear" alt="Vue logo" src="../assets/logo-white.svg">
    </transition>
    <div class="introHome">
      <transition name="txtAppear">
        <div v-show="animAppear" class="ontitle">
          {{ home.date }}
        </div>
      </transition>
      <transition name="txtAppear">
        <h1 v-show="animAppear" >{{ home.title }}</h1>
      </transition>
      <transition name="txtAppear">
        <div v-show="animAppear" class="description">
          <div class="description--title">{{ home.descTitle }}</div>
          <div v-html="home.desc">

          </div>
        </div>
      </transition>
    </div>

    <transition name="btnAppear">
      <router-link v-show="animAppear" class="btn btn-primary" to="/constitution">{{ home.btnTxt }}</router-link>
    </transition>
  </div>
</template>

<script>
  import DataHome from '../../public/data/home.json'

  export default {
    name: 'home',
    data() {
      return {
        home: DataHome,
        animAppear: false
      }
    },
    mounted () {
      this.animAppear = true;
    }
  };
</script>

<style lang="scss">

.page-home {
  &:before {
    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url('../assets/bg-senat.jpg') no-repeat center center;
    background-size: cover;
    z-index: -1;
  }

  &:after {
    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, .3) 100%);
    opacity: .85;
    z-index: -1;
  }
}

.home {
  text-align: center;
  padding: 6.25em 0 3em;
  margin: 0 auto;
  max-width: 55em;
  color: #fff;

  img {
    max-width: 10em;
    display: block;
    margin: 0 auto 2.5em;
    transition: all 3s ease-out;
  }

  .ontitle {
    font-family: $fontNoto;
    font-size: 2.125em;
    margin-bottom: .5em;
    transition: .75s all ease-out;
    transition-delay: .5s;
  }

  h1 {
    font-size: 4.5em;
    line-height: 1;
    margin-bottom: 1em;
    transition: .75s all ease-out;
    transition-delay: 1s;
  }

  .description {
    margin: 0 auto 4em;
    max-width: 46em;
    font-family: $fontNoto;
    transition: .75s all ease-out;
    transition-delay: 1.5s;

    &--title {
      font-size: 1.5em;
      margin-bottom: 1em;
    }
    p {
      margin: 1.5em 0;
    }
  }

  .btn-primary {

  }

}

.logoAppear-enter {
  opacity: 0;
  -webkit-transform:  translateY(-2em) scale(1.2);
  transform:  translateY(-2em) scale(1.2);
}

.txtAppear-enter {
  opacity: 0;
  -webkit-transform:  translateY(30px);
  transform:  translateY(30px);
}

.btnAppear-enter {
  opacity: 0;
  -webkit-transform:  translateY(30px);
  transform:  translateY(30px);
}

.btnAppear-enter-active {
  transition: all .75s ease-out;
  transition-delay: 2s;
}


</style>
